import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';

import { User } from 'src/app/models/user.model';
import { SharedService, AuthService } from 'src/app/services/service.index';

import swal from 'sweetalert2';



@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styles: []
})
export class ProfileComponent implements OnInit {

  public prof:any;
  public profpass:string;
  public profrpass:string;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public _authService: AuthService,
    public _sharedService: SharedService
  ) { 

    //FORCE RELOAD COMPONENT ON CURRENT VIEW
    this.router.routeReuseStrategy.shouldReuseRoute = function(){
      return false;
    }
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
         // trick the Router into believing it's last link wasn't previously loaded
         this.router.navigated = false;
         // if you need to scroll back to top, here is the right place
         window.scrollTo(0, 0);
      }
    });

   }

  ngOnInit() {
    this.getProf();
  }

  changePass(form: NgForm){

    if (!form.valid){
      swal("Cambiar contraseña", "La contraseña no es correcta", "warning");
    }else if(form.value.profpass != form.value.profrpass){
      swal("Cambiar contraseña", "Las contraseñas no son iguales", "warning");
    }else{    

      let user = new User(this._authService.user,form.value.profpass);
      swal({
        text: 'Cambiando...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          swal.showLoading();  
               
          //SERVICE
          this._authService.serviceChgPass(user)
          .subscribe(resp => {
            swal.close();            
            if(resp && resp.Pass.affectedRows == '1' ){
              swal({
                title: 'Contraseña cambiada',
                showConfirmButton: false,
                showCloseButton: false,
                showCancelButton: false,                
                timer: 2000,
                type: 'success',
                onClose: () => {
                  this.router.navigate(['/dashboard'])                  
                }
              });              

            }else{
              swal("Cambiar contraseña", "Error al conectar con el servidor", "error");
              console.log("Response error: " + resp);
            }
          },err => {     
            swal.close();
            swal("Cambiar contraseña", "No se pudo canbiar la contraseña", "error");
            console.log("Server error: " + err.status);
          });
          //END SERVICE
          
        }

      });                        
      
    }    
  }

  getProf(){   
  
    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();     
        //SERVICE
        this._sharedService.serviceProf()
        .subscribe(resp => {
          swal.close();
          if(resp){
            this.prof = resp.Prof;

          }else{
            console.log("Response error: " + resp);
          }
        },err => {     
          swal.close();
          console.log("Server error: " + err.status);
        });
        //END SERVICE
      }

    });                        
  
  }
  
}
