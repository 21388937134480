export class ExpClienteEx {    
    constructor(
        public CCODEXP:string,
        public CPOLIZA:string,
        public CCODCIA:string,
        public CCODGRU:string,
        public DFECEXP:Date,
        public NOTEEXP:string,
        public CCODCLI:any,
    ) { } 
}
