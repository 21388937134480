import { NgModule } from "@angular/core";
import { FormsModule }   from '@angular/forms';

import { SharedModule } from "../shared/shared.module";

import { FullPagesComponent } from "./fullpages.component";
import { AddExpComponent } from './addexp/addexp.component';
import { AddSrvComponent } from './addsrv/addsrv.component';
import { AddCitComponent } from './addcit/addcit.component';
import { ProfileComponent } from "./profile/profile.component";

import { NgSelect2Module } from 'ng-select2';

import { FULLPAGES_ROUTES } from "./fullpages.routes";
import { ModcitComponent } from './modcit/modcit.component';
import { ModservComponent } from './modserv/modserv.component';




@NgModule({
    declarations: [
        FullPagesComponent,
        AddExpComponent,
        AddSrvComponent,
        AddCitComponent,
        ProfileComponent,
        ModcitComponent,
        ModservComponent
    ],
    imports:[
        SharedModule,
        FormsModule,
        NgSelect2Module,
        FULLPAGES_ROUTES
    ],
    exports: [
        FormsModule,
        AddExpComponent,
        AddSrvComponent,
        AddCitComponent,
        ProfileComponent
    ]
})
export class FullPagesModule { }