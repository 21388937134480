import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Srv } from 'src/app/models/srv.model';

import { isNumeric } from "rxjs/util/isNumeric"

import { URL_SERVICE } from 'src/app/config/config';
import { Cit } from 'src/app/models/cit.model';
import { Router } from '@angular/router';
import { Exp } from 'src/app/models/exp.model';
import { ExpClienteEx } from 'src/app/models/exp.modelClientE';

@Injectable({
  providedIn: 'root'
})
export class SharedService {


  reload: EventEmitter<any> = new EventEmitter();
  GoReload(target) {
    this.reload.emit(target);
  }
  GetReload() {
    return this.reload;
  }

  searchword: EventEmitter<string> = new EventEmitter();
  SetSearchWord(word) {
    this.searchword.emit(word);
  }
  GetSearchWord() {
    return this.searchword;
  }



  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    console.log('SharedService Ready!');
  }

  prepareRes(data: any, where?: any, group?: string) {

    let ret = [];
    let aux = data;


    if (where === undefined || where == "") {
      aux = data;
    } else if (typeof (where) == 'object') {
      aux = data;
      for (let field in where) {
        if (typeof (where[field]) == 'string') {
          aux = aux.filter(i => (i[field].toUpperCase().search(where[field].toUpperCase()) >= 0));
        } else if (typeof (where[field]) == 'number') {
          aux = aux.filter(i => (i[field] == where[field]));
        }

      }

    } else if (typeof (where) == 'number' || isNumeric(where)) {

      aux = data.filter(item => {
        for (let fld in item) {


          if (typeof (item[fld]) == 'number') {
            if (item[fld] == where) {
              return true;
            }
          }
        }
      });

    } else if (typeof (where) == 'string') {

      aux = data.filter(item => {
        for (let fld in item) {
          if (typeof (item[fld]) == 'string') {
            if (item[fld].toUpperCase().search(where.toUpperCase()) >= 0) {
              return true;
            }
          }
        }
      });

    } else {
      aux = data;
    }

    if (group === undefined || group == "") {
      ret = aux;
    } else {
      let gro = new Set(aux.map(item => item[group]));
      gro.forEach(g =>
        ret.push({
          Group: g,
          Items: aux.filter(i => i[group] === g)
        }
        ));
    }

    return ret;
  }




  prepareResAgenda(data: any, where?: any, group?: string) {

    let ret = [];
    let aux = data;


    if (where === undefined || where == "") {
      aux = data;
    } else if (typeof (where) == 'object') {
      aux = data;
      for (let field in where) {
        if (typeof (where[field]) == 'string') {
          aux = aux.filter(i => (i[field].toUpperCase().search(where[field].toUpperCase()) >= 0));
        } else if (typeof (where[field]) == 'number') {
          aux = aux.filter(i => (i[field] == where[field]));
        }

      }

    } else if (typeof (where) == 'number' || isNumeric(where)) {

      aux = data.filter(item => {
        for (let fld in item) {
          if (typeof (item[fld]) == 'number') {
            if (item[fld] == where) {
              return true;
            }
          }
        }
      });

    } else if (typeof (where) == 'string') {

      aux = data.filter(item => {
        for (let fld in item) {
          if (typeof (item[fld]) == 'string') {




            if (item[fld].toUpperCase().search(where.toUpperCase()) >= 0) {
              return true;
            }
          }
        }
      });

    } else {
      aux = data;
    }

    if (group === undefined || group == "") {
      ret = aux;
    } else {
      let gro = new Set(aux.map(item => item[group]));
      gro.forEach(g =>
        ret.push({
          Group: g,
          Items: aux.filter(i => i[group] === g)
        }
        ));
    }

    return ret;
  }

  serviceExps(filter: string) {
    let url = URL_SERVICE + '/getExps/' + filter;
    return this.http.get(url)
      .pipe(map((res: any) => {
        return res;
      }), catchError((err: any) => {
        this.resolveError(err);
        return throwError(err);
      })
      );
  }

  serviceExp(exp: number) {
    let url = URL_SERVICE + '/getExp/' + exp;
    return this.http.get(url)
      .pipe(map((res: any) => {
        return res;
      }), catchError((err: any) => {
        this.resolveError(err);
        return throwError(err);
      })
      );
  }

  serviceOpenExp(body: any) {
    let url = URL_SERVICE + '/setOpenExp';
    return this.http.post(url,body)
      .pipe(map((res: any) => {
        return res;
      }), catchError((err: any) => {
        this.resolveError(err);
        return throwError(err);
      })
      );
  }
  serviceCita(cita: number) {
    let url = URL_SERVICE + '/getCita/' + cita;
    return this.http.get(url)
      .pipe(map((res: any) => {
        return res;
      }), catchError((err: any) => {
        this.resolveError(err);
        return throwError(err);
      })
      );
  }


  serviceCitaAgenda() {
    let url = URL_SERVICE + '/getCitaAgenda';
    return this.http.get(url)
      .pipe(map((res: any) => {
        return res;
      }), catchError((err: any) => {
        this.resolveError(err);
        return throwError(err);
      })
      );
  }




  serviceGres() {
    let url = URL_SERVICE + '/getGres';
    return this.http.get(url)
      .pipe(map((res: any) => {
        return res;
      }), catchError((err: any) => {
        this.resolveError(err);
        return throwError(err);
      })
      );
  }

  serviceOpes() {
    let url = URL_SERVICE + '/getOpes';
    return this.http.get(url)
      .pipe(map((res: any) => {
        return res;
      }), catchError((err: any) => {
        this.resolveError(err);
        return throwError(err);
      })
      );
  }

  serviceCias() {
    let url = URL_SERVICE + '/getCias';
    return this.http.get(url)
      .pipe(map((res: any) => {
        return res;
      }), catchError((err: any) => {
        this.resolveError(err);
        return throwError(err);
      })
      );
  }

  serviceGrus() {
    let url = URL_SERVICE + '/getGrus';
    return this.http.get(url)
      .pipe(map((res: any) => {
        return res;
      }), catchError((err: any) => {
        this.resolveError(err);
        return throwError(err);
      })
      );
  }

  serviceAfec() {
    let url = URL_SERVICE + '/getAfec';
    return this.http.get(url)
      .pipe(map((res: any) => {
        return res;
      }), catchError((err: any) => {
        this.resolveError(err);
        return throwError(err);
      })
      );
  }

  serviceProf() {
    let url = URL_SERVICE + '/getProf';
    return this.http.get(url)
      .pipe(map((res: any) => {
        return res;
      }), catchError((err: any) => {
        this.resolveError(err);
        return throwError(err);
      })
      );
  }

  serviceServicesExp(id_exp: any, id_srv: any) {
    let url = URL_SERVICE + '/getServiceMod/' + id_exp + '/' + id_srv;
    console.log(url);
    return this.http.get(url)
      .pipe(map((res: any) => {
        return res;
      }), catchError((err: any) => {
        this.resolveError(err);
        return throwError(err);
      })
      );
  }

  serviceAddSrv(srv: Srv) {
    let url = URL_SERVICE + '/addSrv/' + srv.NNUMEXP;
    return this.http.post(url, srv)
      .pipe(map((res: any) => {
        return res;
      }), catchError((err: any) => {
        this.resolveError(err);
        return throwError(err);
      })
      );
  }

  serviceModSrv(srv: Srv) {
    let url = URL_SERVICE + '/modSrv';
    return this.http.post(url, srv)
      .pipe(map((res: any) => {
        return res;
      }), catchError((err: any) => {
        this.resolveError(err);
        return throwError(err);
      })
      );
  }

  serviceAddCit(cit: Cit) {
    let url = URL_SERVICE + '/addCit/' + cit.NNUMEXP + '/' + cit.NNUMSRV;
    return this.http.post(url, cit)
      .pipe(map((res: any) => {
        return res;
      }), catchError((err: any) => {
        this.resolveError(err);
        return throwError(err);
      })
      );
  }

  serviceModCit(cit: Cit, idcita: any) {
    let url = URL_SERVICE + '/modCit/' + idcita;
    return this.http.post(url, cit)
      .pipe(map((res: any) => {
        return res;
      }), catchError((err: any) => {
        this.resolveError(err);
        return throwError(err);
      })
      );
  }


  serviceAddExp(exp: Exp) {
    let url = URL_SERVICE + '/addExp';
    return this.http.post(url, exp)
      .pipe(map((res: any) => {
        return res;
      }), catchError((err: any) => {
        this.resolveError(err);
        return throwError(err);
      })
      );
  }

  serviceAddExpClienteExist(exp: ExpClienteEx) {
    let url = URL_SERVICE + '/addExpClienteExist';
    return this.http.post(url, exp)
      .pipe(map((res: any) => {
        return res;
      }), catchError((err: any) => {
        this.resolveError(err);
        return throwError(err);
      })
      );
  }

  resolveError(err: any) {
    if (err.status >= 400 && err.status <= 499) {
      this.router.navigate(['/login'])
    }
  }

}
