import { Component,OnInit,} from '@angular/core';
import { RouterOutlet, ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { DashboardComponent } from 'src/app/pages/dashboard/dashboard.component';
import { ExpComponent } from 'src/app/pages/exp/exp.component';
import { SharedService } from 'src/app/services/service.index';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public showback:string = "none";  
  public showsearch: string = "none";
  public showreload: string = "none";
  
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public _sharedService: SharedService    
    ) { 

      router.events.pipe(
        filter(event => event instanceof NavigationEnd)  
      ).subscribe((event: NavigationEnd) => {
        
        this.showback=(this.route.children.find(element  => element.component == ExpComponent)) ? "block" : "none" || "none";  
        this.showsearch=(this.route.children.find(element  => element.component == DashboardComponent)) ? "block" : "none" || "none";
        this.showreload=(this.route.children.find(element  => element.component == DashboardComponent)) ? "block" : "none" || "none";
        
      });
    
  }

  ngOnInit() {

  }

  goBack(){
    this.router.navigate(['../'])
  }

  goHome(){
    this.router.navigate(['/dashboard'])
  }

  goReload(){    
    this._sharedService.GoReload(DashboardComponent);
  }

  searchWord(word: string){    
    this._sharedService.SetSearchWord(word);    
  }

}
