import { Component, Input, Output, ViewChild, OnInit, EventEmitter, HostListener } from '@angular/core';
import { Router, ActivatedRoute, ActivationEnd } from '@angular/router';
import { SharedService, SidebarService } from 'src/app/services/service.index';
import swal from 'sweetalert2';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/retry';
import 'rxjs/add/operator/filter';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public expfilter: string;
  public fullexps: any[];
  public exps: any[];
  public searchword: string;
  subSearchWord: any;
  subReload: any;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public _sidebarService: SidebarService,
    public _sharedService: SharedService
  ) {

    this.getDataRoute()
      .subscribe(data => {
        this.expfilter = data.exp;
      });

  }

  getDataRoute() {
    return this.router.events
      .filter(event => event instanceof ActivationEnd)
      .filter((event: ActivationEnd) => event.snapshot.firstChild === null)
      .map((event: ActivationEnd) => event.snapshot.data);
  }

  ngOnInit() {
    this.getExps();

    this.subSearchWord = this._sharedService.GetSearchWord()
      .subscribe(word => this.searchWord(word));

    this.subReload = this._sharedService.GetReload()
      .subscribe(target => (this instanceof target) ? this.getExps() : console.log(target));
  }

  ngOnDestroy() {
    this.subSearchWord.unsubscribe();
    this.subReload.unsubscribe();
  }

  searchWord(word: string) {
    this.exps = this._sharedService.prepareRes(this.fullexps, word, "DFECEXP");
  }

  getExps() {

    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {

        swal.showLoading();
        this._sharedService.serviceExps(this.expfilter)
          .subscribe(resp => {
            swal.close();
            if (resp) {
              this.fullexps = resp.Exps;
              if (this.expfilter == "24H") {
                this._sidebarService.SetNumExps24h(Object.keys(this.fullexps).length);
                this._sidebarService.SetNumExpsAll(0);
              } else if (this.expfilter == "CLOSE") {

                this._sidebarService.SetNumExpsClose(Object.keys(this.fullexps).length);
                this._sidebarService.SetNumExpsAll(0);
              }

              else {
                this._sidebarService.SetNumExps24h(0);
                this._sidebarService.SetNumExpsAll(Object.keys(this.fullexps).length);
              }
              this.exps = this._sharedService.prepareRes(resp.Exps, "", "DFECEXP");;
            } else {
              console.log("Response error: " + resp);
            }
          }, err => {
            swal.close();
            console.log("Server error: " + err);
          });

      }

    });

  }


}
