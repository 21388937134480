import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeExtraEs from '@angular/common/locales/extra/es';

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule }   from '@angular/forms';

//Config
import { LOCALE } from 'src/app/config/config';

//Router
import { APP_ROUTES } from './app.routes';

//Modules
import { PagesModule } from './pages/pages.module';
import { FullPagesModule } from './fullpages/fullpages.module';
import { SharedModule } from './shared/shared.module';

//Components
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RecoverComponent } from './login/recover.component';
//Services
import { ServiceModule } from './services/service.module';

registerLocaleData(localeEs, LOCALE ,localeExtraEs);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RecoverComponent    
  ],
  imports: [
    BrowserModule,
    FormsModule,
    PagesModule,
    FullPagesModule,
    SharedModule,
    ServiceModule,
    APP_ROUTES,
  ],
  exports: [
    BrowserModule,
    FormsModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: LOCALE }],
  bootstrap: [AppComponent]
})
export class AppModule { }
