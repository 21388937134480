import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";

// AUTH Interceptor
import { TokenInterceptor } from "./auth/token.interceptor";
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import {
    AuthService,
    AuthGuard,
    SharedService,
    SidebarService,    
    CordovaService
} from './service.index';
import { DeviceService, GeolocationService, CameraService, FirebaseService, NetworkInformationService, VibrationService, BatteryStatusService } from "angular-cordova/plugin";
import { CordovaObjService } from "angular-cordova/plugin/cordova-obj";

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        HttpClientModule,        
        AuthService,
        AuthGuard,
        SharedService,
       //Cordova Req        
       CordovaService,        
       DeviceService,
       GeolocationService,
       CameraService,
       FirebaseService,
       NetworkInformationService,
       VibrationService,
       BatteryStatusService,  
       CordovaObjService,
        SidebarService        
    ],
    declarations: []
})
export class ServiceModule { }