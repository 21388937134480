import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/service.index';
import swal from 'sweetalert2';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/retry';
import 'rxjs/add/operator/filter';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {

  public date:any;
  public agenda:any[];
  public FullAgenda:any[];




  public searchword: string;
  subSearchWord: any;
  subReload: any;
  showsearch:any;


  constructor(public _sharedService: SharedService,) { }

  ngOnInit() {

    this.getCitas();

    
    this.subSearchWord = this._sharedService.GetSearchWord()
      .subscribe(word => this.searchWord(word));

  }


  ngOnDestroy() {    
    this.subSearchWord.unsubscribe();
/*     this.subReload.unsubscribe(); */
  }


  getCitas(){    
  
    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();     
        //SERVICE
        this._sharedService.serviceCitaAgenda()
        .subscribe(resp => {
          swal.close();
          if(resp){
            //this.agenda = resp.agenda;
            //console.log(resp.agenda)

            this.FullAgenda = resp.agenda;
            this.agenda = this._sharedService.prepareRes(resp.agenda,"","FECHAAGENDA");

            //console.log(this.agenda)

          }else{
            console.log("Response error: " + resp);
          }
        },err => {     
          swal.close();
          console.log("Server error: " + err.status);
        });
        //END SERVICE
      }

    });                        
  
  }

  goReload(){

    this.getCitas();

  }


  searchWord(word: string) {
    this.agenda = this._sharedService.prepareResAgenda(this.FullAgenda,word,"FECHAAGENDA");
  
  }

  searchDate(date: string) {
    this.agenda = this._sharedService.prepareResAgenda(this.FullAgenda,date,"FECHAAGENDA");
  
  }

}
