import { RouterModule, Routes } from '@angular/router';

import { PagesComponent } from './pages.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { ExpComponent } from './exp/exp.component';

import { AuthGuard } from '../services/service.index';
import { CalendarComponent } from './calendar/calendar.component';

const pagesRoutes: Routes = [
    { path: '', component: PagesComponent,
        canActivate: [ AuthGuard ],
        children: [
            { path: 'dashboard', component: DashboardComponent, data: { tittle: 'Agenda 24h', exp: '24H' }},
            { path: 'dashboardall', component: DashboardComponent, data: { tittle: 'Agenda Completa', exp: 'ALL' }},
            { path: 'dashboardclose', component: DashboardComponent, data: { tittle: 'Agenda Cerrados', exp: 'CLOSE' }},
            { path: 'exp/:exp', component: ExpComponent, data: { tittle: 'Expedientes' }},
            { path: 'calendar', component: CalendarComponent, data: { tittle: 'Calendario' }},
            { path: '', redirectTo: '/dashboardall', pathMatch: 'full'},
        ]
    }
];

export const PAGES_ROUTES = RouterModule.forChild(pagesRoutes);