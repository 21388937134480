export class Exp {    
    constructor(
        public CCODEXP:string,
        public CPOLIZA:string,
        public CCODCIA:string,
        public CCODGRU:string,
        public DFECEXP:Date,
        public NOTEEXP:string,
        public CNOMAFE:string,
        public CDIRAFE:string,
        public CODPAFE:string,
        public CPOBAFE:string,
        public CPROAFE:string,
        public CNIFAFE:string,
        public CTF1AFE:string,
        public CTF2AFE:string
    ) { } 
}
