import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { map, filter, catchError } from 'rxjs/operators';

import { User } from 'src/app/models/user.model';

import { URL_LICEN, URL_SERVICE } from 'src/app/config/config';


@Injectable({ providedIn: 'root' })
//@Injectable()
export class AuthService {

  user: string;
  token: string;

  constructor(
    public router: Router,
    public http: HttpClient
  ) {
    this.serviceLoadStorage();
    console.log('AuthService Ready!');
  }

  serviceIsLogged() {

    try {
      let payload = JSON.parse(atob(this.token.split('.')[1]));
      return this.checkTokenExp(payload.exp) && (payload.user == this.user);
    }
    catch (err) {
      return false;
    }

  }

  private checkTokenExp(exp: number): boolean {
    let tokenExp = new Date(exp * 1000);
    let now = new Date();

    now.setTime(now.getTime() + (1 * 60 * 60 * 1000));

    if (tokenExp.getTime() > now.getTime()) {
      return true;
    } else {
      return false;
    }

  }

  serviceLoadStorage() {
    if (localStorage.getItem('token') && localStorage.getItem('user')) {
      this.token = localStorage.getItem('token') || '';
      this.user = localStorage.getItem('user') || '';
    } else {
      this.token = '';
      this.user = '';
    }
  }

  serviceLogout() {
    this.token = '';
    this.user = '';
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }

  serviceLogin(user: User, remember: boolean = false) {
    let url = URL_SERVICE + '/login';

    if (remember) {
      localStorage.setItem('mail', user.mail);
    } else {
      localStorage.removeItem('mail');
    }

    return this.http.post(url, user)
      .pipe(map((res: any) => {
        localStorage.setItem('user', user.mail);
        localStorage.setItem('token', res.token);

        this.user = user.mail;
        this.token = res.token;

        return res;
      }), catchError((err: any) => {
        return throwError(err);
      })
      );
  }


  serviceChgPass(user: User) {
    let url = URL_SERVICE + '/chgPass';
    return this.http.post(url, user)
      .pipe(map((res: any) => {
        return res;
      }), catchError((err: any) => {
        this.resolveError(err);
        return throwError(err);
      })
      );
  }


  serviceRecPass(user: string) {
    let url = URL_SERVICE + '/recPass/' + user;
    return this.http.post(url, user)
      .pipe(map((res: any) => {
        return res;
      }), catchError((err: any) => {
        this.resolveError(err);
        return throwError(err);
      })
      );
  }

  resolveError(err: any) {
    if (err.status >= 400 && err.status <= 499) {
      this.router.navigate(['/login'])
    }
  }

   //COMPROBAMOS LA LICENCIA
   public serviceGetLicense(data: any) {
    let url = URL_LICEN + data;
    return this.http.get(url)
      .pipe(map((res: any) => {
        return res;
      }), catchError((err: any) => {
        return throwError(err);
      })
      );

  }

}
