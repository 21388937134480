export class Cit {    
    constructor(
        public NNUMEXP: number,
        public NNUMSRV: number,
        public CCODPRO: string,
        public CESTADO: string,
        public DFECINI: Date,
        public DFECFIN: Date,
        public LURGENT: boolean,
        public COBSSRV: string,
        public CEXPED?: any
    ) { } 
}
