import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  numexps24h: EventEmitter<number> = new EventEmitter();
  SetNumExps24h(count) {
    this.numexps24h.emit(count);
  }
  GetNumExps24h() {
    return this.numexps24h;
  }

  numexpsall: EventEmitter<number> = new EventEmitter();
  SetNumExpsAll(count) {
    this.numexpsall.emit(count);
  }
  GetNumExpsAll() {
    return this.numexpsall;
  }


  numexpsclose: EventEmitter<number> = new EventEmitter();
  SetNumExpsClose(count) {
    this.numexpsclose.emit(count);
  }
  GetNumExpsClose() {
    return this.numexpsclose;
  }







  constructor() { }
}
