import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

declare function initCustomJS();
@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styles: []
})
export class PagesComponent implements OnInit {

  constructor(
    public router: Router,
    private elementref: ElementRef
  ) { }

  ngAfterViewInit() {
    this.elementref.nativeElement.querySelector('mini-sidebar');
  } 

  ngOnInit() {
    this.hideSidebar();
    initCustomJS();
  }

  hideSidebar(){    
    if (this.elementref.nativeElement.offsetParent.classList.value){
      this.elementref.nativeElement.offsetParent.classList.value=this.elementref.nativeElement.offsetParent.classList.value.toString().replace('show-sidebar','');
    }
  }

}
