import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';

import { SharedService } from 'src/app/services/service.index';
import { Cit } from 'src/app/models/cit.model';
import { LOCALE } from 'src/app/config/config';


import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';

import swal from 'sweetalert2';




@Component({
  selector: 'app-addcit',
  templateUrl: './addcit.component.html',
  styles: []
})
export class AddCitComponent implements OnInit {

  public numexp: number;
  public numsrv: number;
  public CEXPED: any;
  
  public opecit:string;
  public dateinicit:Date;
  public datefincit:Date;
  public notecit:string;
  public urgcit:boolean;

  public opes: Array<Select2OptionData>;
  public opeOpt: Options;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public _sharedService: SharedService
  ) { 

    //FORCE RELOAD COMPONENT ON CURRENT VIEW
    this.router.routeReuseStrategy.shouldReuseRoute = function(){
      return false;
    }
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
         // trick the Router into believing it's last link wasn't previously loaded
         this.router.navigated = false;
         // if you need to scroll back to top, here is the right place
         window.scrollTo(0, 0);
      }
    });


    //GET PARAM ON ROUTER OVERLOAD
    activatedRoute.params.subscribe( params => {
      this.numexp = params['exp'];
      this.numsrv = params['srv'];
    });

   }

  ngOnInit() {

    this.CEXPED = localStorage.getItem('CEXPED') || '';

    this.opeOpt = {
      language: 'es'
    };
    this.urgcit = true;
    this.dateinicit = new Date();
    this.datefincit = new Date();
    this.getOpes();
  }

  fixFinCit(time:any){
    var datePipe = new DatePipe(LOCALE);
    this.datefincit = new Date(datePipe.transform(this.dateinicit, 'yyyy/MM/dd') + ' ' + time);
  } 

  getOpes(){    
  
    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();     
        //SERVICE
        this._sharedService.serviceOpes()
        .subscribe(resp => {
          swal.close();
          if(resp){
            this.opes = resp.Opes;
          }else{
            console.log("Response error: " + resp);
          }
        },err => {     
          swal.close();
          console.log("Server error: " + err.status);
        });
        //END SERVICE
      }

    });                        
  
  }

  addCit(form: NgForm){
    
    if (!form.valid){
      swal("Crear Cita", "Los campos de la cita no son correctos", "warning");
    }
    else{

      var datePipe = new DatePipe(LOCALE);
      this.datefincit = new Date(datePipe.transform(this.dateinicit, 'yyyy/MM/dd') + ' ' + datePipe.transform(this.datefincit, 'HH:mm:ss'));
      let cit = new Cit(this.numexp,this.numsrv,this.opecit,'PENDIENTE',this.dateinicit,this.datefincit,this.urgcit,this.notecit,this.CEXPED);
      //console.log(cit);
       swal({
        text: 'Cuardando...',
        allowOutsideClick: false,
        onBeforeOpen: () => {

          swal.showLoading();          
          //SERVICE
          this._sharedService.serviceAddCit(cit)
          .subscribe(resp => {
            swal.close();

            if(resp){
              swal({
                title: 'Cita creada',
                showConfirmButton: false,
                showCloseButton: false,
                showCancelButton: false,                
                timer: 2000,
                type: 'success',
                onClose: () => {
                  this.router.navigate(['/exp/' + this.numexp])                  
                }
              });              

            }else{
              swal("Crear Cita", "Error al conectar con el servidor", "error");
              console.log("Response error: " + resp);
            }
          },err => {     
            swal.close();
            swal("Crear Cita", "No se pudo guardar la cita", "error");
            console.log("Server error: " + err.status);
          });
          //END SERVICE
        }

      });
      
    }    
  }


}
