import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Select2OptionData } from 'ng-select2';
import { Srv } from 'src/app/models/srv.model';
import { SharedService } from 'src/app/services/service.index';
import swal from 'sweetalert2';

@Component({
  selector: 'app-modserv',
  templateUrl: './modserv.component.html',
  styleUrls: ['./modserv.component.css']
})
export class ModservComponent implements OnInit {

  public numexp: number;
  public srv: number;


  public gresrv: string;
  public notesrv: string;
  public datesrv: any;

  //@ViewChild('imgTest')domimgTest: ElementRef;

  public gres: Array<Select2OptionData>;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public _sharedService: SharedService
  ) {

    //FORCE RELOAD COMPONENT ON CURRENT VIEW
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
        // if you need to scroll back to top, here is the right place
        window.scrollTo(0, 0);
      }
    });


    //GET PARAM ON ROUTER OVERLOAD
    activatedRoute.params.subscribe(params => {
      this.numexp = params['exp'];
      this.srv = params['srv'];
    });


  }

  ngAfterViewInit() {
    //console.log(this.domimgTest);
  }

  ngOnInit() {
    this.datesrv = new Date();
    this.getGres();
    this.getService();
  }

  modSrv(form: NgForm) {

    if (!form.valid) {
      swal("Crear Servicio", "Los campos del servicio no son correctos", "warning");
    } else {
      let selgresrv = this.gres.filter(function (item) {
        return item.id === form.value.gresrv;
      })[0];

      let srv = new Srv(this.numexp, this.srv, selgresrv.id, selgresrv.text, this.datesrv, this.notesrv);

      //console.log(srv);
      swal({
        text: 'Cuardando...',
        allowOutsideClick: false,
        onBeforeOpen: () => {

          swal.showLoading();
          //SERVICE
          this._sharedService.serviceModSrv(srv)
            .subscribe(resp => {
              swal.close();

              if (resp) {
                swal({
                  title: 'Servicio creado',
                  showConfirmButton: false,
                  showCloseButton: false,
                  showCancelButton: false,
                  timer: 2000,
                  type: 'success',
                  onClose: () => {
                    this.router.navigate(['/exp/' + this.numexp])
                  }
                });

              } else {
                swal("Crear Servicio", "Error al conectar con el servidor", "error");
                console.log("Response error: " + resp);
              }
            }, err => {
              swal.close();
              swal("Crear Servicio", "No se pudo guardar el servicio", "error");
              console.log("Server error: " + err.status);
            });
          //END SERVICE
        }

      });

    }
  }

  getGres() {

    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();
        //SERVICE
        this._sharedService.serviceGres()
          .subscribe(resp => {
            swal.close();
            if (resp) {
              this.gres = resp.Gres;
            } else {
              console.log("Response error: " + resp);
            }
          }, err => {
            swal.close();
            console.log("Server error: " + err.status);
          });
        //END SERVICE
      }

    });

  }


  getService() {


    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();
        //SERVICE
        this._sharedService.serviceServicesExp(this.numexp, this.srv)
          .subscribe(resp => {
            swal.close();
            if (resp) {
              this.datesrv = resp.SRV[0].DFINI;
              this.notesrv = resp.SRV[0].CTEXTASIS;
            } else {
              console.log("Response error: " + resp);
            }
          }, err => {
            swal.close();
            console.log("Server error: " + err.status);
          });
        //END SERVICE
      }

    });

  }




}
