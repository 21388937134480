import { RouterModule, Routes } from '@angular/router';

import { FullPagesComponent } from './fullpages.component';

import { AddExpComponent } from './addexp/addexp.component';
import { AddSrvComponent } from './addsrv/addsrv.component';
import { AddCitComponent } from './addcit/addcit.component';
import { ProfileComponent } from './profile/profile.component';

import { AuthGuard } from '../services/service.index';
import { ModcitComponent } from './modcit/modcit.component';
import { ModservComponent } from './modserv/modserv.component';

const fullpagesRoutes: Routes = [
    { path: '', component: FullPagesComponent,
        canActivate: [ AuthGuard ],
        children: [
            { path: 'profile', component: ProfileComponent, data: { tittle: 'Perfil de usuario' }},
            { path: 'addexp', component: AddExpComponent, data: { tittle: 'Nuevo expediente' }},
            { path: 'addsrv/:exp', component: AddSrvComponent, data: { tittle: 'Nuevo servicio' }},
            { path: 'modsrv/:exp/:srv', component: ModservComponent, data: { tittle: 'Modificar servicio' }},
            { path: 'addcit/:exp/:srv', component: AddCitComponent, data: { tittle: 'Nueva cita' }},
            { path: 'modcit/:exp/:srv/:idcita', component: ModcitComponent, data: { tittle: 'Modificación cita' }},
            { path: '', redirectTo: '/dashboardall', pathMatch: 'full'},
        ]
    }
];

export const FULLPAGES_ROUTES = RouterModule.forRoot(fullpagesRoutes,{useHash: true});