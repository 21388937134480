import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/services/service.index';
import { Exp } from 'src/app/models/exp.model';
import swal from 'sweetalert2';
import { Select2OptionData } from 'ng-select2';
import { NgForm } from '@angular/forms';
import { ExpClienteEx } from 'src/app/models/exp.modelClientE';



@Component({
  selector: 'app-addexp',
  templateUrl: './addexp.component.html',
  styles: []
})
export class AddExpComponent implements OnInit {

  public codexp:string;
  public polexp:string;
  public ciaexp:string;
  public cafect:string;
  public gruexp:string;
  public dateexp:Date;
  public noteexp:string;

  public nomafe:string;
  public dirafe:string;
  public cpafe:string;
  public pobafe:string;
  public proafe:string;
  public nifafe:string;
  public tf1afe:string;
  public tf2afe:string;

  public cias: Array<Select2OptionData>;
  public grus: Array<Select2OptionData>;
  public afec: Array<Select2OptionData>;


  public cexiste:boolean = false;
  public cnexiste:boolean = false;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public _sharedService: SharedService
  ) { 

    //FORCE RELOAD COMPONENT ON CURRENT VIEW
    this.router.routeReuseStrategy.shouldReuseRoute = function(){
      return false;
    }
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
         // trick the Router into believing it's last link wasn't previously loaded
         this.router.navigated = false;
         // if you need to scroll back to top, here is the right place
         window.scrollTo(0, 0);
      }
    });

   }

  ngOnInit() {
    this.codexp = "";
    this.polexp = "";
    this.ciaexp = "";
    this.cafect="";
    this.gruexp = "";
    this.noteexp = "";
  
    this.nomafe = "";
    this.dirafe = "";
    this.cpafe = "";
    this.pobafe = "";
    this.proafe = "";
    this.nifafe = "";
    this.tf1afe = "";
    this.tf2afe = "";    
    this.dateexp = new Date();
    this.getCias();
    this.getGrus();
    this.getAfect();
  
  }

  getCias(){    
  
    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();     
        //SERVICE
        this._sharedService.serviceCias()
        .subscribe(resp => {
          swal.close();
          if(resp){
            this.cias = resp.Cias;
          }else{
            console.log("Response error: " + resp);
          }
        },err => {     
          swal.close();
          console.log("Server error: " + err.status);
        });
        //END SERVICE
      }

    });                        
  
  }

  getGrus(){    
  
    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();     
        //SERVICE
        this._sharedService.serviceGrus()
        .subscribe(resp => {
          swal.close();
          if(resp){
            this.grus = resp.Grus;
          }else{
            console.log("Response error: " + resp);
          }
        },err => {     
          swal.close();
          console.log("Server error: " + err.status);
        });
        //END SERVICE
      }

    });                        
  
  }


  getAfect(){    

    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();     
        //SERVICE
        this._sharedService.serviceAfec()
        .subscribe(resp => {
          swal.close();
          if(resp){
            this.afec = resp.Afec;
          }else{
            console.log("Response error: " + resp);
          }
        },err => {     
          swal.close();
          console.log("Server error: " + err.status);
        });
        //END SERVICE
      }

    });                        
  
  }

  addExp(form: NgForm){
 
    if (!form.valid){
      swal("Crear Expediente", "Los campos del expediente no son correctos", "warning");
    }else{
      //console.log(this.tf2afe);
      let exp = new Exp(this.codexp,this.polexp,this.ciaexp,this.gruexp,this.dateexp,this.noteexp,this.nomafe,this.dirafe,this.cpafe,this.pobafe,this.proafe,this.nifafe,this.tf1afe,this.tf2afe);
      swal({
        text: 'Cuardando...',
        allowOutsideClick: false,
        onBeforeOpen: () => {

          swal.showLoading();          
          //SERVICE
          this._sharedService.serviceAddExp(exp)
          .subscribe(resp => {
            swal.close();

            if(resp){
              swal({
                title: 'Expediente creado',
                showConfirmButton: false,
                showCloseButton: false,
                showCancelButton: false,                
                timer: 2000,
                type: 'success',
                onClose: () => {
                  this.router.navigate(['/exp/' + resp.NREG])                  
                }
              });              

            }else{
              swal("Crear Cita", "Error al conectar con el servidor", "error");
              console.log("Response error: " + resp);
            }
          },err => {     
            swal.close();
            swal("Crear Cita", "No se pudo guardar el expediente", "error");
            console.log("Server error: " + err.status);
          });
          //END SERVICE
        }

      });                        
      
    }   
  }

  existe(){

    this.cexiste = true;
    this.cnexiste = false;


  }

  noexiste(){
    this.cnexiste = true;
    this.cexiste = false;
  }

  


  sendDataClientNoExist(){

    let exp = new Exp(this.codexp,this.polexp,this.ciaexp,this.gruexp,this.dateexp,this.noteexp.toUpperCase(),this.nomafe.toUpperCase(),this.dirafe.toUpperCase(),this.cpafe.toUpperCase(),this.pobafe.toUpperCase(),this.proafe.toUpperCase(),this.nifafe.toUpperCase(),this.tf1afe,this.tf2afe);
    
    //console.log(exp);
     swal({
      text: 'Cuardando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {

        swal.showLoading();          
        //SERVICE
        this._sharedService.serviceAddExp(exp)
        .subscribe(resp => {
          swal.close();

          if(resp){
            swal({
              title: 'Expediente creado',
              showConfirmButton: false,
              showCloseButton: false,
              showCancelButton: false,                
              timer: 2000,
              type: 'success',
              onClose: () => {
                this.router.navigate(['/exp/' + resp.NREG])                  
              }
            });              

          }else{
            swal("Crear Cita", "Error al conectar con el servidor", "error");
            console.log("Response error: " + resp);
          }
        },err => {     
          swal.close();
          swal("Crear Cita", "No se pudo guardar el expediente", "error");
          console.log("Server error: " + err.status);
        });
        //END SERVICE
      }

    });
    
}

sendDataClientExist(){

      let expClienteExistente = new ExpClienteEx(this.codexp,this.polexp,this.ciaexp,this.gruexp,this.dateexp,this.noteexp,this.cafect);


    swal({
        text: 'Cuardando...',
        allowOutsideClick: false,
        onBeforeOpen: () => {

          swal.showLoading();          
          //SERVICE
          this._sharedService.serviceAddExpClienteExist(expClienteExistente)
          .subscribe(resp => {
            swal.close();

            if(resp){
              swal({
                title: 'Expediente creado',
                showConfirmButton: false,
                showCloseButton: false,
                showCancelButton: false,                
                timer: 2000,
                type: 'success',
                onClose: () => {
                  //console.log(resp.NREG);
                  this.router.navigate(['/exp/' + resp.NREG])                  
                }
              });              

            }else{
              swal("Crear Cita", "Error al conectar con el servidor", "error");
              console.log("Response error: " + resp);
            }
          },err => {     
            swal.close();
            swal("Crear Cita", "No se pudo guardar el expediente", "error");
            console.log("Server error: " + err.status);
          });
          //END SERVICE
        }

      });
      
    



  }

}
