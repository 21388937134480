import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { map, catchError, timeout  } from 'rxjs/operators';

import { Cordova } from 'angular-cordova';
import { DeviceService } from 'angular-cordova/plugin/device';
import { GeolocationService } from 'angular-cordova/plugin/geolocation';
import { CameraService, CameraDirection, CameraPictureSourceType, CameraMediaType, CameraDestinationType } from 'angular-cordova/plugin/camera';
import { FirebaseService } from 'angular-cordova/plugin/firebase';
import { NetworkInformationService } from 'angular-cordova/plugin/network-information';
import { VibrationService } from 'angular-cordova/plugin/vibration';
import { BatteryStatusService } from 'angular-cordova/plugin/battery-status';
import { CordovaObjService } from 'angular-cordova/plugin/cordova-obj';

@Injectable({
  providedIn: 'root'
})
export class CordovaService {

  public uuid: string;
  public serial: string;
  public manufacturer: string;
  public model: string;
  public platform: string;
  public version: string;

  constructor(
    private deviceService: DeviceService,
    private geolocationService: GeolocationService,
    private cameraService: CameraService,
    private firebaseService: FirebaseService,
    private networkInformationService: NetworkInformationService,
    private vibrationService: VibrationService,
    private batteryStatusService: BatteryStatusService,
    private cordovaObjService: CordovaObjService
  ) { 
    this.getDevice_uuid().subscribe(res => {
      this.uuid = res;      
    });

    this.getDevice_serial().subscribe(res => {
      this.serial = res;
    });
    
    this.getDevice_manufacturer().subscribe(res => {
      this.manufacturer = res;
    });
    
    this.getDevice_model().subscribe(res => {
      this.model = res;
    });
    
    this.getDevice_platform().subscribe(res => {
      this.platform = res;
    });
    
    this.getDevice_version().subscribe(res => {
      this.version = res;
    });    

    console.log("CordovaService ready!");
  }

  private getDevice_uuid(){
    return this.deviceService.uuid().pipe(map((res: any) => {                  
      return res;
    }),catchError((err: any) => {
        return throwError(err);
    })
    );        
  }

  private getDevice_serial(){
    return this.deviceService.serial().pipe(map((res: any) => {                  
      return res;
    }),catchError((err: any) => {
        return throwError(err);
    })
    );        
  }  

  private getDevice_manufacturer(){
    return this.deviceService.manufacturer().pipe(map((res: any) => {                  
      return res;
    }),catchError((err: any) => {
        return throwError(err);
    })
    );        
  }  

  private getDevice_model(){
    return this.deviceService.model().pipe(map((res: any) => {                  
      return res;
    }),catchError((err: any) => {
        return throwError(err);
    })
    );        
  }  

  private getDevice_platform(){
    return this.deviceService.platform().pipe(map((res: any) => {                  
      return res;
    }),catchError((err: any) => {
        return throwError(err);
    })
    );        
  }  

  private getDevice_version(){
    return this.deviceService.version().pipe(map((res: any) => {                  
      return res;
    }),catchError((err: any) => {
        return throwError(err);
    })
    );        
  }  

  getCordova(){
    return this.cordovaObjService.cordova().pipe(map((res: any) => {                  
      return res;
    }),catchError((err: any) => {
        return throwError(err);
    })
    );        
  }

  serviceOnDeviceReady(){
    return Cordova.deviceready.subscribe(() => {
      console.log("Device ready!");
    }),catchError((err: any) => {
      console.log("Device not ready!");
      return throwError(err);
    });
  }  

  servicePosition(){

    return this.geolocationService.getCurrentPosition().pipe(map((res: any) => {                  
      return res;
    }),catchError((err: any) => {
        return throwError(err);
    })
    );
  }  

  serviceOnMove(maximumAge?:number,timeout?:number,enableHighAccuracy?:boolean){
    let options = { 
      maximumAge: maximumAge || 3000, 
      timeout: timeout || 10000, 
      enableHighAccuracy: 
      enableHighAccuracy || true 
    };
    return this.geolocationService.watchPosition(options).pipe(map((res: any) => {                  
      return res;
    }),catchError((err: any) => {
        return throwError(err);
    })
    );
  }  

  servicePicture(destinationType?:CameraDestinationType, cameraDirection?:CameraDirection, quality?:number,correctOrientation?:boolean, allowEdit?:boolean, saveToPhotoAlbum?:boolean, targetWidth?:number, targetHeight?:number){
    
    let options = { 
      sourceType : CameraPictureSourceType.CAMERA, 
      mediaType : CameraMediaType.PICTURE, 

      destinationType : destinationType || CameraDestinationType.FILE_URI,
      allowEdit : allowEdit || false,
      cameraDirection : cameraDirection || CameraDirection.BACK,
      correctOrientation : correctOrientation || false,
      quality : quality || 50,
      saveToPhotoAlbum : saveToPhotoAlbum || false,
      targetWidth : targetWidth || 3000,
      targetHeight : targetHeight || 2000
    };    
    
    return this.cameraService.getPicture(options).pipe(map((res: any) => {                  
      return res;
    }),catchError((err: any) => {
        return throwError(err);
    })
    );
  }  

  serviceVideo(destinationType?:CameraDestinationType, cameraDirection?:CameraDirection, quality?:number,correctOrientation?:boolean, saveToPhotoAlbum?:boolean, targetWidth?:number, targetHeight?:number){
    
    let options = { 
      sourceType : CameraPictureSourceType.CAMERA, 
      mediaType : CameraMediaType.VIDEO, 

      destinationType : destinationType || CameraDestinationType.FILE_URI,
      cameraDirection : cameraDirection || CameraDirection.BACK,
      correctOrientation : correctOrientation || false,
      quality : quality || 50,
      saveToPhotoAlbum : saveToPhotoAlbum || false,
      targetWidth : targetWidth || 3000,
      targetHeight : targetHeight || 2000
    };    
    
    return this.cameraService.getPicture(options).pipe(map((res: any) => {                  
      return res;
    }),catchError((err: any) => {
        return throwError(err);
    })
    );
  }  

  servicePictureGallery(destinationType?:CameraDestinationType){
    
    let options = { 
      sourceType : CameraPictureSourceType.PHOTOLIBRARY, 
      mediaType : CameraMediaType.PICTURE, 

      destinationType : destinationType || CameraDestinationType.FILE_URI
    };    
    
    return this.cameraService.getPicture(options).pipe(map((res: any) => {                  
      return res;
    }),catchError((err: any) => {
        return throwError(err);
    })
    );
  }  

  serviceVideoGallery(destinationType?:CameraDestinationType){
    
    let options = { 
      sourceType : CameraPictureSourceType.PHOTOLIBRARY, 
      mediaType : CameraMediaType.VIDEO, 

      destinationType : destinationType || CameraDestinationType.FILE_URI
    };    
    
    return this.cameraService.getPicture(options).pipe(map((res: any) => {                  
      return res;
    }),catchError((err: any) => {
        return throwError(err);
    })
    );
  }  




  servicePushToken(){

    return this.firebaseService.getToken().pipe(map((res: any) => {                  
      return res;
    }),catchError((err: any) => {
        return throwError(err);
    })
    );
  }  


  serviceOnPush(){

    return this.firebaseService.onNotificationOpen().pipe(map((res: any) => {                  
      return res;
    }),catchError((err: any) => {
        return throwError(err);
    })
    );
  }  



  serviceNetworkInformation(){

    return this.networkInformationService.connectionType().pipe(map((res: any) => {                  
      return res;
    }),catchError((err: any) => {
        return throwError(err);
    })
    );
  }    

  serviceOnOnline(){

    return this.networkInformationService.online().pipe(map((res: any) => {                  
      return res;
    }),catchError((err: any) => {
        return throwError(err);
    })
    );
  }    

  serviceOnOffline(){

    return this.networkInformationService.offline().pipe(map((res: any) => {                  
      return res;
    }),catchError((err: any) => {
        return throwError(err);
    })
    );
  }    




  serviceVibrate(time?:number | number[]){

    time = time || 1500;

    return this.vibrationService.vibrate(time).pipe(map((res: any) => {                  
      return res;
    }),catchError((err: any) => {
        return throwError(err);
    })
    );
  }   



  serviceBatteryStatus(){
    return this.batteryStatusService.batterystatus.pipe(map((res: any) => {                  
      return res;
    }),catchError((err: any) => {
        return throwError(err);
    })
    );    
  }    

  serviceOnLowBattery(){
    return this.batteryStatusService.batterylow.pipe(map((res: any) => {                  
      return res;
    }),catchError((err: any) => {
        return throwError(err);
    })
    );
  }    

  serviceOnCriticalBattery(){
    return this.batteryStatusService.batterycritical.pipe(map((res: any) => {                  
      return res;
    }),catchError((err: any) => {
        return throwError(err);
    })
    );
  }  


}
