import { Component, OnInit } from '@angular/core';
import { AuthService, SharedService, SidebarService } from 'src/app/services/service.index';
import swal from 'sweetalert2';
import { Router } from '@angular/router';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: []
})
export class SidebarComponent implements OnInit {

  numexps24h:number = 0;
  numexpsall:number = 0;
  numexpsclose:number = 0;


  subNumExps24h: any;
  subNumExpsAll: any;
  subNumExpsClose: any;


  constructor(
    public router: Router,
    public _authService: AuthService,
    public _sidebarService: SidebarService
  ) { } 

  ngOnInit() {
    this.subNumExps24h = this._sidebarService.GetNumExps24h()
    .subscribe(count => this.numExps24h(count));

    this.subNumExpsAll = this._sidebarService.GetNumExpsAll()
    .subscribe(count => this.numExpsAll(count));


    this.subNumExpsClose = this._sidebarService.GetNumExpsClose()
    .subscribe(count => this.numExpsClose(count));

  }

  ngOnDestroy() {    
    this.subNumExps24h.unsubscribe();
    this.subNumExpsAll.unsubscribe();
    this.subNumExpsClose.unsubscribe();
  }

  numExps24h(count: number) {
    this.numexps24h = count;
  }

  numExpsAll(count: number) {
    this.numexpsall = count;
  }

  numExpsClose(count: number) {
    this.numexpsclose = count;
  }

  logout() {   

    swal({
      title: 'Cerrar sesión',
      text: "¿Esta seguro de finalzar la sesión?",
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((ret) => {
      if (ret.value) {
        swal(
          'Cerrar sesión',
          'La sesión ha finalizado.',
          'success'
        );
        this._authService.serviceLogout();
      }
    })    
  }

}
