import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";

import { PagesComponent } from "./pages.component";
import { DashboardComponent } from './dashboard/dashboard.component';
import { ExpComponent } from './exp/exp.component';

import { NgSelect2Module } from 'ng-select2';

import { PAGES_ROUTES } from "./pages.routes";
import { CalendarComponent } from './calendar/calendar.component';
import { FormsModule } from "@angular/forms";


@NgModule({
    declarations: [
        PagesComponent,
        DashboardComponent,
        ExpComponent,
        CalendarComponent,
    ],
    imports:[
        SharedModule,
        NgSelect2Module,
        PAGES_ROUTES,
        FormsModule
    ],
    exports: [
        DashboardComponent,
        ExpComponent,
        CalendarComponent
    ]
})
export class PagesModule { }