import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';

import { SharedService } from 'src/app/services/service.index';
import { Cit } from 'src/app/models/cit.model';
import { LOCALE, URL_SERVICE } from 'src/app/config/config';


import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';

import swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
import { CordovaService } from 'src/app/services/cordova/cordova.service';


@Component({
  selector: 'app-modcit',
  templateUrl: './modcit.component.html',
  styleUrls: ['./modcit.component.css']
})
export class ModcitComponent implements OnInit {

  public numexp: number;
  public numsrv: number;
  public numcita: number;

  public cita: any;



  public opecit: string;
  public dateinicit: Date;
  public datefincit: Date;
  public notecit: string;
  public tpendientes: string;
  public trealizados: string;
  public mutilizados: string;




  public urgcit: any;
  public cestado: any;

  public opes: Array<Select2OptionData>;
  public opeOpt: Options;


  public fotos: any;

  public fechaIni: any;
  public fechaFin: any;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public _sharedService: SharedService,
    public sanitizer: DomSanitizer,
    public _cordovaService: CordovaService
  ) {


    // FORCE RELOAD COMPONENT ON CURRENT VIEW
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
        // if you need to scroll back to top, here is the right place
        window.scrollTo(0, 0);
      }
    });


    // GET PARAM ON ROUTER OVERLOAD
    activatedRoute.params.subscribe(params => {
      this.numexp = params['exp'];
      this.numsrv = params['srv'];
      this.numcita = params['idcita'];
    });

  }

  ngOnInit() {


    this.opeOpt = {
      language: 'es'
    };
    this.urgcit = true;
    this.dateinicit = new Date();
    this.datefincit = new Date();
    this.getOpes();
    this.getDataCita();

  }

  fixFinCit(time: any) {
    const datePipe = new DatePipe(LOCALE);
    this.datefincit = new Date(datePipe.transform(this.dateinicit, 'yyyy/MM/dd') + ' ' + time);
  }

  getDataCita() {

    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();
        // SERVICE
        this._sharedService.serviceCita(this.numcita)
          .subscribe(resp => {
            if (resp) {

              //console.log(resp);

              this.cita = resp.cita[0];
              this.notecit = this.cita.CTREALIZAR;
              this.tpendientes = this.cita.CTPENDIENTES;
              this.trealizados = this.cita.CTREALIZADOS;
              this.mutilizados = this.cita.CMATERIALES;
              this.opecit = this.cita.CCODPRO;
              this.urgcit = this.cita.LURGENTE === 1 ? true : false;
              this.cestado = this.cita.CESTADO;
              this.fotos = resp.fotos;

              this.fechaIni = this.cita.fechaIni;
              this.fechaFin = this.cita.fechaFin;

              swal.close();

            } else {
              console.log('Response error: ' + resp);
            }
          }, err => {
            swal.close();
            switch (err.status) {
              case 302: {
                this.router.navigate(['/exp/'+this.numexp]);
                swal("Cita", "Cita no accesible.", "error");
                break
              }
              default: {
                swal("Cita", "No se pudo abrir la cita.", "error");
                break;
              }
            }
          });
        // END SERVICE
      }

    });

  }


  getOpes() {

    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();
        // SERVICE
        this._sharedService.serviceOpes()
          .subscribe(resp => {
            swal.close();
            if (resp) {
              this.opes = resp.Opes;
            } else {
              console.log('Response error: ' + resp);
            }
          }, err => {
            swal.close();
            console.log('Server error: ' + err.status);
          });
        // END SERVICE
      }

    });

  }

  modCit(form: NgForm) {
    if (!form.valid) {
      swal('Modificar Cita', 'Los campos de la cita no son correctos', 'warning');
    } else if (this.dateinicit > this.datefincit) {
      swal('Modificar Cita', 'La fecha de la cita no es correcta', 'warning');
    } else {



      this.urgcit = this.urgcit === true ? 1 : 0;
      const datePipe = new DatePipe(LOCALE);
      this.datefincit = new Date(datePipe.transform(this.dateinicit, 'yyyy/MM/dd') + ' ' + datePipe.transform(this.datefincit, 'HH:mm:ss'));
      const cit = new Cit(this.numexp, this.numsrv, this.opecit, this.cita.CESTADO, this.dateinicit, this.datefincit, this.urgcit, this.notecit);
      //console.log(cit);
      swal({
        text: 'Cuardando...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          swal.showLoading();
          // SERVICE
          this._sharedService.serviceModCit(cit, this.numcita)
            .subscribe(resp => {
              swal.close();
              if (resp) {
                swal({
                  title: 'Cita Modificada',
                  showConfirmButton: false,
                  showCloseButton: false,
                  showCancelButton: false,
                  timer: 2000,
                  type: 'success',
                  onClose: () => {
                    this.router.navigate(['/exp/' + this.numexp]);
                  }
                });

              } else {
                swal('Crear Cita', 'Error al conectar con el servidor', 'error');
                console.log('Response error: ' + resp);
              }
            }, err => {
              swal.close();
              swal('Crear Cita', 'No se pudo guardar la cita', 'error');
              console.log('Server error: ' + err.status);
            });
          // END SERVICE
        }

      });
    }
  }

  zoomImage(foto) {


    swal({
      //title: '¿Descargar esta imagen?',
      //showCancelButton: true,
      //confirmButtonColor: '#3085d6',
      //cancelButtonColor: '#d33',
      //confirmButtonText: 'SI',
      imageUrl: foto.CFOTO,
      imageHeight: 500,
      imageWidth: 500,
      //cancelButtonText: 'NO'
    }).then((result) => {
      if (result.value) {
        //this.getImage(foto.CUUID);
      }
    })
  }


  getImage(uuid: string) {
    let url = URL_SERVICE + '/downloadImages/' + this.numcita + '/' + uuid;
    //console.log(url);
    if (this._cordovaService.uuid) {
      this._cordovaService.getCordova().subscribe(resp => {
        if (resp) {
          resp.InAppBrowser.open(encodeURI(url), "_system", "location=no,clearsessioncache=no,clearcache=no");
        } else {
          console.log("Cordova error: " + resp);
        }
      }, err => {
        console.log("Cordova Service error: " + err);
      });
    } else {
      window.open(encodeURI(url), "_self", "location=no,clearsessioncache=no,clearcache=no");
    }

  }



}

