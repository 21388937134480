export class Srv {    
    constructor(
        public NNUMEXP: number,
        public NNUMSRV: number,
        public CCODGRE: string,
        public CNOMGRE: string,
        public DFECGRE: Date,
        public COBSSRV: string
    ) { } 
}
