import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { SharedService } from 'src/app/services/service.index';
import swal from 'sweetalert2';

@Component({
  selector: 'app-exp',
  templateUrl: './exp.component.html',
  styles: []
})
export class ExpComponent implements OnInit {

  public numexp: number;
  public exp: any;
  public tfns: any;
  public srvs: any;
  public cits: any;

  public cierre = '';

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public _sharedService: SharedService
  ) {

    //FORCE RELOAD COMPONENT ON CURRENT VIEW
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
        // if you need to scroll back to top, here is the right place
        window.scrollTo(0, 0);
      }
    });


    //GET PARAM ON ROUTER OVERLOAD
    activatedRoute.params.subscribe(params => {
      this.numexp = params['exp'];
    });

  }

  ngOnInit() {
    this.getExp();
  }

  showNote(note: string) {

    swal({
      title: 'Notas del servicio',
      text: note,
      type: 'info',
      showCancelButton: false,
      confirmButtonText: 'Cerrar'
    });

  }

  showDetailExp(note: string) {

    swal({
      title: 'Descripción Expediente',
      text: note,
      type: 'info',
      showCancelButton: false,
      confirmButtonText: 'Cerrar'
    });

  }

  showMap(gmap: string) {

    swal({
      html:
        '<div style="width: 100%"><iframe width="100%" height="500" src="' + gmap + '" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"><a href="https://www.mapsdirections.info/calcular-ruta.html">Mapa de Ruta</a></iframe></div><br />',
      showCancelButton: false,
      confirmButtonText: 'Cerrar'
    });

  }

  getExp() {

    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();
        //SERVICE
        this._sharedService.serviceExp(this.numexp)
          .subscribe(resp => {
            swal.close();
            if (resp) {

              console.log(resp.Exp);

              this.exp = resp.Exp;
              this.tfns = resp.Tfns;
              this.srvs = resp.Srvs;
              this.cits = resp.Cits;

              //PARA SABER SI ESTA CERRADO O NO EL EXPEDIENTE.
              this.cierre = this.exp[0].CIERRE;

              //console.log(this.cits);
              localStorage.setItem('CEXPED', this.exp[0].CCODEXP);
              //this.srvs = this._sharedService.prepareRes(resp.Srvs,"","CCODSERV");
            } else {
              console.log("Response error: " + resp);
            }
          }, err => {
            swal.close();
            console.log("Server error: " + err.status);
          });
        //END SERVICE
      }

    });

  }

  modService(srv: any) {

    console.log(srv);
    this.router.navigate(['/modsrv/' + this.numexp + '/' + srv]);

  }


  openExpediet() {

    let body = { IDEXP: this.numexp };

    //console.log(body);


    swal({
      titleText: '¿Estas serguro?',

      cancelButtonText: "NO",
      showCancelButton: true,
      cancelButtonColor: "red",

      confirmButtonText: "SI",

    }).then((result) => {

      if (result.value) {
       

        swal({
          text: 'Cargando...',
          allowOutsideClick: false,
          onBeforeOpen: () => {
            swal.showLoading();
            //SERVICE
            this._sharedService.serviceOpenExp(body)
              .subscribe(resp => {
                swal.close();
                if (resp) {
                  window.location.reload();
                  swal('Correcto!', '', 'success');

                } else {
                  console.log("Response error: " + resp);
                }
              }, err => {
                swal.close();
                console.log("Server error: " + err.status);
              });
            //END SERVICE
          }

        });


      } else if (result.dismiss) {
        swal('La acción no se ha realizado', '', 'info')
      }


    })

  }
}
